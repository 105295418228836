
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~swiper/scss";
@import "~swiper/scss/navigation";
@import "~swiper/scss/pagination";
@import "~@ionic/angular/css/ionic-swiper";
@import "../src/assets/my-scss/my-variables.scss"; // Le css boostrap

$circle-width-heigth: 30px;
$circle-width: $circle-width-heigth;
$circle-height: $circle-width-heigth;
$circle-border-radius: 50%;

ion-header::after {
  display: none;
}
.height-5 {
  height: 5px;
}
.height-1 {
  height: 1px;
}
.height-15 {
  height: 15px;
}
.height-30 {
  height: 30px;
}
.card-footer-button {
  background-color: white;
}
.my-swiper {
  height: 100%;
}
.my-no-wrap{
  text-wrap:nowrap
}

.swiper-1{
  .swiper-slide-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #fff;
    height: 100%;
    max-width: 600px;
    margin: auto;
    border-radius: 20px;
  
  
  }
    .swiper-button-next  {
      color:  $ion-color-secondary ;
  
    }
    .swiper-button-next:after{
      font-size: 30px;
    }
    .swiper-button-prev  {
      color:  $ion-color-secondary ;
    }
    .swiper-button-prev:after{
      font-size: 30px;
    }
  .swiper-pagination-bullet-active {
    // background-color: $ion-color-secondary !important;
    width: 16px;
    height: 16px;
  }
  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
        background-color: $ion-color-primary !important;

  }
 .swiper-slide{
  font-size: 14px;
 }

}

.circle {
  background: $ion-color-secondary;
  border-radius: $circle-border-radius;
  width: $circle-width;
  height: $circle-height;
}
.circle-primary {
  background: $ion-color-primary;
  border-radius: $circle-border-radius;
  width: $circle-width;
  height: $circle-height;
}
.circle-green {
  background: green;
  border-radius: $circle-border-radius;
  width: $circle-width;
  height: $circle-height;
}
.circle-medium {
  background: $ion-color-medium;
  border-radius: $circle-border-radius;
  width: $circle-width;
  height: $circle-height;
}
.num {
  color: white;
}
.horizontal-line {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #d4d4e0;
  margin: auto;
}
.my-container-500 {
  max-width: 500px;
  padding-left: 15px;
  padding-right: 15px;
}
.my-container-500-without-padding-15 {
  max-width: 500px;
}
.my-container-150 {
  max-width: 150px;
  padding-left: 15px;
  padding-right: 15px;
}
.side-15 {
  margin-right: 15px;
  margin-left: 15px;
}
.bbagde-shopping-cart{
  .mat-badge-content{
    top:0px !important
  }
}
.mar-30 {
  margin: 30px;
}
.mar-t-b-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mat-form-field-required-marker {
  color: red;
}

.label-16 {
  font-size: 16px;
}
.my-modal {
  .ion-page {
    overflow: auto;
  }
}
.my-chip-primary {
  --background: #00213f;
  --color: #adefd1;
}
.my-chip-secondary {
  --background: #fd74130f;
  --color: #fd7413;
}
.my-modal-spinner {
      --height: 190px;
      --width: 250px;
}
.my-modal-video {
     --height: auto;
      --width: 560px;
}
.my-overflow {
  overflow: auto;
}
.red-color {
  color: red;
}
.font-size-12 {
  font-size: 12px;
}
.text-color {
  color: #666666;
}
.p-b-50 {
  padding-bottom: 50px;
}
.my-pagination ::ng-deep .ngx-pagination .current {
  background: $ion-color-primary;
}
.alert-radio-label.sc-ion-alert-md {
  white-space: normal !important;
  font-size: 13px;
}
.invalid{
    color: red;
}
.info-tooltip{
    font-size: 20px;
    color:$ion-color-primary
}
.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination{
  //utilisé dans le composant home section comment ça marche
  display: none !important;
}
.m-form-no-padding{
    .mat-form-field-wrapper{
        padding: 0px !important;

    }
}
.custom-stepper{
  .mat-vertical-content{
    padding: 5px !important;
  }
  .mat-step-icon-state-done{
    background-color: #198754 !important;
  }
  .mat-step-label-active{
    font-weight: 500 !important;
    font-size: 15px !important;
  }
}
.my-toolbar{
    background-color: white !important;
}
ion-toolbar {
  --background: white !important;
}
   .whatsapp-color {
    color: #25D366;
  }

.custom-pag-left{
  .mat-paginator-container{
        justify-content: flex-start !important;

} 
}
