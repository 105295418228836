// breapoints à utiliser dans les css.On centralise tout si on doit changer. 
//mes breakpoins
$xsm:320px ;//mmax 320px par exemple
// les breakpoints ionic
$sm:576px ;//min 576px par exemple
$md:768px ;
$lg:992px ;
$xl:1200px ;
 $ion-color-secondary: #fd7413;
 $ion-color-primary: #00B4CC;
 $ion-color-medium: #92949c;